@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "styles/breakpoints.scss";

/* Global styling variables */
html {
  --color-background: #ddbbfc;
  --button-height-short: 50px;
  --button-height-long: 65px;
  --button-height-tablet: 94px;
  --button-max-width: 480px;
  --breakpoint-height: 650px;
}

html,
body {
  height: 100%;
  overscroll-behavior-y: contain;
  /* prevent pull-to-refresh in mobile Chrome */
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a,
a:visited {
  color: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: black;
  background-color: white;
}

@media (min-height: $breakpoint-height) {
  body {
    font-size: 24px;
  }
}

@media (min-width: $breakpoint-width-tablet) {
  body {
    font-size: 40px;
  }
}

#root {
  height: 100%;
  background-color: var(--color-background);
}

.flex-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.overflow-scroll {
  overflow: scroll !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-black {
  color: black;
}

.font-bold {
  font-weight: bold;
}

small {
  font-size: 12px;
}
