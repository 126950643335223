@import "styles/breakpoints.scss";

.Layout {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* fall-back */
  min-height: -moz-available;
  min-height: -webkit-stretch-available;
  min-height: stretch-available;
  height: 100%;
}

@media (min-width: $breakpoint-width-tablet) {
  .Layout {
    text-align: center;
  }
}

.Header {
  background-color: black;
  height: 45px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 7px 19px 7px 23px;
}

@media (min-height: $breakpoint-height) {
  .Header {
    height: 79px;
  }
}

.Header__link {
  width: 100%;
  height: 100%;
  display: flex;
}

.Header__logo {
  height: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.Main {
  position: relative;
  background-color: var(--color-background);
  height: 100%;
  overflow: hidden;
}

.Header__inner,
.Main__inner {
  margin: 0 auto;
  max-width: 834px;
  height: 100%;
}

.Main__inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: normal;
  padding: 0 15px;
}

@media (min-width: $breakpoint-width-tablet) {
  .Main__inner {
    padding: 0 40px 40px;
  }
}

.Fabutton {
  background-color: white;
  border: 2px solid black;
  height: var(--button-height-short);
  max-width: var(--button-max-width);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

@media (min-height: $breakpoint-height) {
  .Fabutton {
    height: var(--button-height-long);
  }
}

@media (min-width: $breakpoint-width-tablet) {
  .Fabutton {
    height: 94px;
    font-size: 35px;
    width: var(--button-max-width);
  }

  .vertical-grid .Fabutton {
    font-size: 24px;
    height: 65px;
    width: 340px;
  }
}

.Fabutton--active {
  background-color: black;
  color: white;
  border-color: white;
}

.DoubleColumnContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media (min-width: $breakpoint-width-tablet) {
  .DoubleColumnContainer {
    max-width: 486px;
    margin: 0 auto;
  }
}

.DoubleColumnContainer>div {
  flex-basis: 50%;
  flex-shrink: 0;
  margin-bottom: 10px;
}

@media (min-height: $breakpoint-height) {
  .DoubleColumnContainer>div {
    margin-bottom: 21px;
  }
}

.DoubleColumnContainer>div:nth-child(even) {
  border-left: none;
}

.DoubleColumnContainer>div:last-child {
  flex-grow: 1;
}

@media (min-width: $breakpoint-width-tablet) {
  .DoubleColumnContainer>div {
    margin-bottom: 37px;
  }
}

.WeiterButton {
  width: 135px;
  margin-left: auto;
  margin-bottom: 20px;
  margin-top: auto;
  flex-shrink: 0;
}

.WeiterButton--disabled {
  pointer-events: none;
  color: gray;
}

@media (min-height: $breakpoint-height) {
  .WeiterButton {
    width: 172px;
  }
}

@media (min-width: $breakpoint-width-tablet) {
  .WeiterButton {
    width: 244px;
  }
}

.FabuttonNext--disabled {
  pointer-events: none;
  color: gray;
}

.MultiplePictoChoiceTool {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: row;
  overflow: hidden;
  margin-bottom: 10px;
}

.MultiplePictoChoiceTool__Column {
  position: relative;
  flex-basis: 33%;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
}

.MultiplePictoChoiceTool .ClickablePicto {
  max-width: 100%;
  max-height: 37%;
}

.ClickablePicto {
  cursor: pointer;
  flex-shrink: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*height: 10vh;*/
}

.ClickablePicto--active {}

.ClickablePicto__image {
  min-height: 0;
  max-width: 100%;
}

.ClickablePicto__textlayer {
  background-color: black;
  color: white;
  padding: 2px 5px;
  font-size: 10px;
}

@media (min-width: $breakpoint-width-tablet) {
  .ClickablePicto__textlayer {
    font-size: 24px;
  }
}

.RobiGif {
  position: fixed;
  z-index: 111;
  pointer-events: none;
}

.RobiGif img {
  width: 100%;
}

.RobiGifComposite {
  position: fixed;
  z-index: 111;
  pointer-events: none;
}

.RobiGifFlex {
  flex-grow: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  pointer-events: none;
}

.RobiGifFlexComposite {
  flex-grow: 1;
  position: relative;
  pointer-events: none;
}

.RobiGifFlexComposite__inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

.RobiGifSpecial {
  position: fixed;
  pointer-events: none;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: 29px;
}

.RobiGifSpecial img {
  height: 100%;
}

.FabSlider {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  height: 100%;
  margin-bottom: 10px;
}

.FabSlider__elementTop,
.FabSlider__elementBottom {
  width: 40px;
  height: auto;
}

@media (min-height: $breakpoint-height) {

  .FabSlider__elementTop,
  .FabSlider__elementBottom {
    width: 63px;
  }
}

.FabSlider__image {
  width: 100%;
}

.FabSlider__bar {
  position: relative;
  flex-grow: 1;
  max-height: 250px;
  width: 25px;
  background-color: white;
  border: 2px solid black;
  position: relative;
  margin: 10px 0;
}

.FabSlider__bar__filler {
  background-color: black;
  width: 100%;
  position: absolute;
  bottom: 0;
  min-height: 10px;
}

.MuiSlider-thumbColorSecondary,
.MuiSlider-track,
.MuiSlider-rail,
.MuiSlider-mark {
  visibility: hidden;
}

.FabTextInput {
  width: 100%;
  max-width: var(--button-max-width);
}

@media (min-width: $breakpoint-width-tablet) {
  .FabTextInput {
    margin: 0 auto;
  }
}

.FabTextInput__input {
  width: 100%;
  height: var(--button-height-short);
  border: 2px solid black;
  font-size: inherit;
  font-family: inherit;
  text-align: center;
}

@media (min-height: $breakpoint-height) {
  .FabTextInput__input {
    height: var(--button-height-long);
  }
}

@media (min-width: $breakpoint-width-tablet) {
  .FabTextInput__input {
    height: var(--button-height-tablet);
    width: 480px;
  }
}

.FabTextArea {
  width: 100%;
}

.FabTextArea__inner {
  height: var(--button-height);
  width: 100%;
  border: 2px solid black;
  font-size: inherit;
  font-family: inherit;
  margin-bottom: 15px;
}

.vertical-grid {
  height: 100%;
  display: grid;
  grid-gap: 10px;
  overflow-y: scroll;
  margin: 0 auto;
  position: relative;
}

@media (min-height: $breakpoint-height) {
  .vertical-grid {
    grid-gap: 26px;
  }
}

@media (min-width: $breakpoint-width-tablet) {
  .vertical-grid__outerouter {
    margin: 0 auto;
  }
}

.vertical-grid__outer {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.vertical-grid__outer--overflowing::after {
  content: "-";
  width: 100%;
  height: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(30, 30, 30, 0.3));
  position: absolute;
  bottom: 0;
  left: 0;
}

.vertical-grid__outerouter {
  overflow: hidden;
  margin-bottom: 20px;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.DataExportScreen {
  padding: 2em;
  font-size: 18px;
  max-width: 500px;
  margin: 0 auto;

  h1 {
    font-size: 30px;
  }
}

.BackButton {
  background: none;
  border: none;
  cursor: pointer;
}

.question-and-back {
  display: flex;
}

.question-and-back p {
  margin-left: 13px;
}


.Alert {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  color: white;
  padding: 18px;
  padding-top: 100px;
}

.Alert__bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 888;
  background-color: black;
  opacity: 0.8;
}

.Alert__close {
  display: block;
  margin-left: auto;
}